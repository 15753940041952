<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h3 class="m-0">Gerenciamento de formulários</h3>
      </b-card-header>

      <b-card-body>
        <b-table
          :items="formularios"
          :fields="fields"
          :busy="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          show-empty
        >
          <template #empty>
            <div class="text-center">Nenhum formulário encontrado.</div>
          </template>

          <template v-slot:cell(local_inicio)="data">
            <a
              :href="
                googleMapsLink(
                  data.item.latitude_inicio,
                  data.item.longitude_inicio
                )
              "
              target="_blank"
              class="btn btn-icon btn-link"
            >
              <img
                src="/media/mapa.png"
                alt="Google Maps"
                style="height: 30px"
              />
            </a>
          </template>

          <template v-slot:cell(local_fim)="data">
            <a
              :href="
                googleMapsLink(data.item.latitude_fim, data.item.longitude_fim)
              "
              target="_blank"
              class="btn btn-icon btn-link"
            >
              <img
                src="/media/mapa.png"
                alt="Google Maps"
                style="height: 30px"
              />
            </a>
          </template>

          <template v-slot:cell(acao)="data">
            <b-dropdown
              variant="clean"
              size="small"
              toggle-class="btn-icon"
              no-caret
            >
              <template #button-content>
                <i class="fas fa-ellipsis-h"></i>
              </template>

              <b-dropdown-item @click="imprimir(data.item.id)"
                >Imprimir</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer>
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            class="m-0"
          ></b-pagination>

          <div class="d-flex align-items-center flex-wrap">
            <b-select v-model="perPage" size="sm" class="mr-4">
              <option
                v-for="option in [20, 50, 100]"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </b-select>

            <span>
              Exibindo de {{ (currentPage - 1) * perPage + 1 }} até
              {{ currentPage * perPage }} de {{ totalRows }} registros.
            </span>
          </div>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormularioService from "@/core/services/formulario.service";
import moment from "@/core/plugins/moment";

export default {
  name: "formulario-listar",
  data() {
    return {
      formularios: [],
      loading: false,
      fields: [
        {
          key: "data_cadastro",
          label: "Data",
          formatter: "dataCadastroFormatter",
          class: "text-center align-middle",
          sortable: true,
        },
        {
          key: "usuario.nome",
          label: "Usuário",
          sortable: true,
          class: "align-middle",
        },
        {
          key: "formulario.nome",
          label: "Formulário",
          class: "align-middle",
          sortable: true,
        },
        {
          key: "local_inicio",
          label: "Local início",
          class: "text-center align-middle",
        },
        {
          key: "local_fim",
          label: "Local fim",
          class: "text-center align-middle",
        },
        { key: "acao", label: "Ação", class: "text-center align-middle" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Formulários",
      },
    ]);

    this.getFormularios();
  },

  methods: {
    getFormularios() {
      this.loading = true;

      FormularioService.getFormularios()
        .then((response) => {
          this.formularios = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onPageChange(page) {
      this.currentPage = page;
      this.getUsuarios();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dataCadastroFormatter(value) {
      return value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "";
    },

    async imprimir(formularioId) {
      await FormularioService.imprimirFormulario(formularioId).then(
        (response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
      );
    },

    googleMapsLink(latitude, longitude) {
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    },
  },
};
</script>

<style scoped>
.custom-select-sm {
  height: calc(1.244em + 1.1rem + 2px) !important;
  width: 70px !important;
}
</style>
